import { createBrowserHistory, createMemoryHistory } from 'history';
import { createStore } from 'redux';

import { INITIAL_STATE as INITIAL_STATE_ARVP } from './anonymousRecentlyViewedProducts/anonymousRecentlyViewedProducts';
import { INITIAL_STATE as INITIAL_STATE_ALP } from './anonymousLikedProducts/anonymousLikedProducts';
import combinedReducers from './reducers';
import composedMiddlewares from './middlewares';
import { isServer } from '../utils/isServer';

export default (url = '/') => {
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory();

  const initialState = !isServer
    ? {
        //todo: reduce initial state to minimum needed
        ...window.__PRELOADED_STATE__,
        anonymousRecentlyViewedProducts: INITIAL_STATE_ARVP,
        anonymousLikedProducts: INITIAL_STATE_ALP,
      }
    : {};

  const store = createStore(combinedReducers(history), initialState, composedMiddlewares(history));

  return {
    history,
    store,
  };
};
