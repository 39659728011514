import PropTypes from 'prop-types';

/** Actions' constants */
export const ACTION_SET_CATEGORIES = 'SET_CATEGORIES';
export const ACTION_SET_CATEGORIES_ELASTIC_SEARCH = 'SET_CATEGORIES_ELASTIC_SEARCH';
export const ACTION_SET_MAIN_CATEGORIES = 'SET_MAIN_CATEGORIES';
export const ACTION_SET_CATEGORIES_TREE = 'SET_CATEGORIES_TREE';
export const ACTION_SET_ROUTES = 'SET_ROUTES';

/** Initial state */
export const INITIAL_STATE = {
  elasticSearch: null,
  main: null,
  tree: [],
  categoriesRoutes: [],
};

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_CATEGORIES:
      return { ...currentState, main: payload };
    case ACTION_SET_CATEGORIES_TREE:
      return { ...currentState, tree: payload };
    case ACTION_SET_CATEGORIES_ELASTIC_SEARCH:
      return { ...currentState, elasticSearch: payload };
    //TODO: same as ACTION_SET_CATEGORIES
    case ACTION_SET_MAIN_CATEGORIES:
      return { ...currentState, main: payload };
    case ACTION_SET_ROUTES:
      return { ...currentState, categoriesRoutes: payload };
    default:
      return currentState;
  }
};

/** Action (creators) */
//todo: not used anywhere
export const setCategories = (categories) => ({
  payload: categories.map(({ img, img_blue, name, slug }) => ({ img, img_blue, name, slug })),
  type: ACTION_SET_CATEGORIES,
});

export const setMainCats = (payload) => {
  return {
    payload: payload.categories.reduce((output, cat) => {
      output[cat.slug] = {
        img_blue: cat.img_blue,
        img: cat.img,
        name: cat.name,
        slug: cat.slug,
        id: cat.id,
      };

      return output;
    }, {}),
    type: ACTION_SET_MAIN_CATEGORIES,
  };
};

export const setRoutes = (payload) => {
  return {
    payload,
    type: ACTION_SET_ROUTES,
  };
};

export const setElasticSearchCategories = (payload) => {
  return {
    payload: payload.reduce((output, cat) => {
      output[cat.slug] = {
        adult_content: !!cat.adult_content,
        key: cat.key,
        name: cat.key,
        slug: cat.slug,
        img: cat.img,
        subCategories: cat.subcategory.buckets.reduce((subCatOutput, subCat) => {
          subCatOutput[subCat.slug] = {
            adult_content: !!cat.adult_content || !!subCat.adult_content,
            key: subCat.key,
            slug: subCat.slug,
            img: subCat.img,
            subSubCategories: subCat.subsubcategory.buckets.reduce((subSubCatOutput, subSubCat) => {
              subSubCatOutput[subSubCat.slug] = {
                adult_content: !!cat.adult_content || !!subCat.adult_content || !!subSubCat.adult_content,
                key: subSubCat.key,
                slug: subSubCat.slug,
                img: subSubCat.img,
              };

              return subSubCatOutput;
            }, {}),
          };

          return subCatOutput;
        }, {}),
      };
      return output;
    }, {}),
    type: ACTION_SET_CATEGORIES_ELASTIC_SEARCH,
  };
};

//todo: not used anywhere
export const setTree = (categories) => {
  const tree = categories.reduce((output, mainCategory) => {
    const children = mainCategory.tags
      .sort((a, b) => (a.level < b.level ? -1 : 1))
      .reduce((innerOutput, tag) => {
        if (tag.level === '2') {
          return {
            ...innerOutput,
            [tag.slug]: {
              ...tag,
              children: [],
            },
          };
        }

        return {
          ...innerOutput,
          [tag.parent]: { ...innerOutput[tag.parent], children: innerOutput[tag.parent].children.concat([tag]) },
        };
      }, {});

    delete mainCategory.tags;
    return {
      ...output,
      [mainCategory.slug]: {
        ...mainCategory,
        children,
      },
    };
  }, {});

  return { payload: tree, type: ACTION_SET_CATEGORIES_TREE };
};

export const CATEGORIES_MAIN_CATEGORIES_SHAPE = PropTypes.shape({
  img: PropTypes.string,
  img_blue: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
});
