export const ACTION_TRIGGER_CHAT = 'ACTION_TRIGGER_CHAT';

export const INITIAL_STATE = {
  openChat: false,
};

export const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TRIGGER_CHAT:
      return { ...state, openChat: payload };
    default:
      return state;
  }
};

export const setTriggerChat = (payload) => ({ payload, type: ACTION_TRIGGER_CHAT });
