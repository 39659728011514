/** Actions' constants */
export const ACTION_SET_PROFILE = 'SET_PROFILE';
export const ACTION_TRIGGER_LOG_OUT = 'ACTION_TRIGGER_LOG_OUT';

export const INITIAL_STATE = null;

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_PROFILE:
      return payload ? { ...payload } : null;
    case ACTION_TRIGGER_LOG_OUT:
      return { ...currentState, openLogout: payload };
    default:
      return currentState;
  }
};

/** Actions */
export const setProfile = (payload) => ({ payload, type: ACTION_SET_PROFILE });
export const setTriggerLogout = (payload) => ({ payload, type: ACTION_TRIGGER_LOG_OUT });
