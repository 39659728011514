/** Actions' constants */
export const ACTION_SET_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';
export const ACTION_ADD_SEARCH_HISTORY_ITEM = 'ADD_SEARCH_HISTORY_ITEM';

/** Initial state */
//use null to distinguish between not initialised search history and actually empty search history
export const INITIAL_STATE = null;

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_SEARCH_HISTORY:
      return payload;
    case ACTION_ADD_SEARCH_HISTORY_ITEM:
      return [payload, ...currentState];
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setSearchHistory = (payload) => ({ payload, type: ACTION_SET_SEARCH_HISTORY });
export const addSearchHistoryItem = (payload) => ({ payload, type: ACTION_ADD_SEARCH_HISTORY_ITEM });
