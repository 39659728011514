export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}

export function register() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`${process.env.REACT_APP_BASE_URL}/sw.js`);
  }
}
