export const ACTION_ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ACTION_RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const ACTION_REMOVE_LAST_NOTIFICATION = 'REMOVE_LAST_NOTIFICATION';

export const INITIAL_STATE = {
  list: [],
};

export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';

export const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_ADD_NOTIFICATION:
      return {
        ...state,
        list: [...state.list, payload],
      };
    case ACTION_RESET_NOTIFICATIONS:
      return {
        ...state,
        list: [],
      };
    case ACTION_REMOVE_LAST_NOTIFICATION:
      return {
        ...state,
        list: state.list.slice(1, state.list.length - 1),
      };
    default:
      return state;
  }
};

export const addNotification = (payload) => {
  return {
    type: ACTION_ADD_NOTIFICATION,
    payload,
  };
};

export const resetNotifications = () => {
  return {
    type: ACTION_RESET_NOTIFICATIONS,
  };
};

export const removeLastNotification = () => {
  return {
    type: ACTION_REMOVE_LAST_NOTIFICATION,
  };
};
