/** Actions' constants */
export const ACTION_SET_IMAGE_SEARCH = 'SET_IMAGE_SEARCH';
export const ACTION_TRIGGER_UPLOAD_IMAGE = 'ACTION_TRIGGER_UPLOAD_IMAGE';
export const ACTION_SET_PRODUCT_SEARCH = 'SET_PRODUCT_SEARCH';

/** Initial state */
export const INITIAL_STATE = {
  imageUrl: null,
  searchProducts: {},
  openUploadImage: false,
};

export const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_IMAGE_SEARCH:
      let imageSearch = JSON.parse(localStorage.getItem('imageSearch') || '[]');
      if (!Array.isArray(imageSearch)) {
        imageSearch = [];
      }
      const checkExist = imageSearch.some((img) => img.id === payload?.id);
      if (!checkExist && payload?.url) {
        imageSearch.unshift({ ...payload, date: new Date() });
        localStorage.setItem('imageSearch', JSON.stringify(imageSearch));
      }
      return { ...state, imageUrl: payload };
    case ACTION_SET_PRODUCT_SEARCH:
      return { ...state, searchProducts: payload };
    case ACTION_TRIGGER_UPLOAD_IMAGE:
      return { ...state, openUploadImage: payload };
    default:
      return state;
  }
};

/** Action (creators) */
export const setImageSearch = (payload) => ({ payload, type: ACTION_SET_IMAGE_SEARCH });
export const setSearchProducts = (payload) => ({ payload, type: ACTION_SET_PRODUCT_SEARCH });
export const setTriggerUploadImage = (payload) => ({ payload, type: ACTION_TRIGGER_UPLOAD_IMAGE });
