/** Actions' constants */
export const ACTION_SET_DAILIES = 'SET_DAILIES';

export const INITIAL_STATE = null;

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_DAILIES:
      return payload ? [...payload] : null;
    default:
      return currentState;
  }
};

/** Actions */
export const setDailies = (payload) => ({ payload, type: ACTION_SET_DAILIES });
