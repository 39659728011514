export const ACTION_SET_RECOMMENDATION_CATEGORY = 'ACTION_SET_RECOMMENDATION_CATEGORY';

export const INITIAL_STATE = {
  recommendationCategory: '',
};

export const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_RECOMMENDATION_CATEGORY: {
      localStorage.setItem('currentCategory', payload);
      return {
        ...state,
        recommendationCategory: payload,
      };
    }
    default:
      return state;
  }
};

export const setRecommendationCategory = (payload) => {
  return {
    type: ACTION_SET_RECOMMENDATION_CATEGORY,
    payload,
  };
};
