import PropTypes from 'prop-types';

/** Actions' constants */
export const ACTION_SET_CONFIG = 'SET_CONFIG';

/** Initial state */
export const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  country: process.env.REACT_APP_WEBSITE_COUNTRY,
  shopName: process.env.REACT_APP_WEBSITE_DOMAIN,
};

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_CONFIG:
      return { ...currentState, ...payload };
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setConfig = (config) => ({ payload: config, type: ACTION_SET_CONFIG });
//todo: update config shape according to updated init.json
export const CONFIG_SHAPE = PropTypes.shape({
  baseUrl: PropTypes.string,
  cmsSlugs: PropTypes.objectOf(PropTypes.string),
  facets: PropTypes.shape({
    default: PropTypes.arrayOf(PropTypes.string),
    sortValues: PropTypes.shape({
      color: PropTypes.objectOf(PropTypes.string),
      gender: PropTypes.arrayOf(PropTypes.string),
      size: PropTypes.shape({
        pants: PropTypes.arrayOf(PropTypes.string),
        shoes: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
  head: PropTypes.shape({
    description: PropTypes.string,
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        charset: PropTypes.string,
        content: PropTypes.string,
        description: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    titleTemplate: PropTypes.string,
  }),
  mail: PropTypes.shape({
    from: PropTypes.string,
    receivers: PropTypes.shape({
      sales: PropTypes.shape({
        email: PropTypes.string,
      }),
    }),
  }),
  numberOfCategoriesInFooter: PropTypes.number,
  numberOfItemsInWishlistPopup: PropTypes.number,
  numberOfRecentSearches: PropTypes.number,
  numberOfScrollableItemsInFilter: PropTypes.number,
  numberOfVisibleItemsInFilter: PropTypes.number,
  social: PropTypes.objectOf(PropTypes.string),
  theme: PropTypes.shape({
    colors: PropTypes.objectOf(PropTypes.string),
    images: PropTypes.objectOf(PropTypes.string),
    logos: PropTypes.objectOf(PropTypes.string),
  }),
});
