export const ACTION_SET_SUBSCRIPTION_PRODUCTS = 'ACTION_SET_SUBSCRIPTION_PRODUCTS';
export const ACTION_ADD_SUBSCRIPTION_PRODUCT = 'ACTION_ADD_SUBSCRIPTION_PRODUCT';
export const ACTION_REMOVE_SUBSCRIPTION_PRODUCT = 'ACTION_REMOVE_SUBSCRIPTION_PRODUCT';

export const INITIAL_STATE = { backInStockSubscriptions: [] };

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_SUBSCRIPTION_PRODUCTS:
      return { ...currentState, backInStockSubscriptions: payload };
    case ACTION_ADD_SUBSCRIPTION_PRODUCT:
      if (currentState.backInStockSubscriptions.find(({ id }) => id === payload.id)) return currentState;
      else return { ...currentState, backInStockSubscriptions: [...currentState.backInStockSubscriptions, payload] };
    case ACTION_REMOVE_SUBSCRIPTION_PRODUCT:
      return {
        ...currentState,
        backInStockSubscriptions: currentState.backInStockSubscriptions.filter(({ id }) => id !== payload.id),
      };
    default:
      return currentState;
  }
};

export const setBackInStockSubscriptions = (payload) => ({ payload, type: ACTION_SET_SUBSCRIPTION_PRODUCTS });
export const addBackInStockSubscription = (payload) => ({ payload, type: ACTION_ADD_SUBSCRIPTION_PRODUCT });
export const removeBackInStockSubscription = (payload) => ({ payload, type: ACTION_REMOVE_SUBSCRIPTION_PRODUCT });
