export const ACTION_SET_CART_SESSION = 'SET_CART_SESSION';
export const ACTION_SET_CART_CONTENTS = 'SET_CART_CONTENTS';
export const ACTION_SET_PENDING_PAYMENT = 'SET_PENDING_PAYMENT';
export const ACTION_SET_CART_ERRORS = 'ACTION_SET_CART_ERRORS';

export const INITIAL_STATE = {
  cartSessionHash: null,
  openCarts: [],
  pendingPayments: [],
  cartErrors: [],
};

export const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_CART_SESSION:
      return {
        ...state,
        cartSessionHash: payload,
      };
    case ACTION_SET_CART_CONTENTS:
      return {
        ...state,
        openCarts: payload,
      };
    case ACTION_SET_PENDING_PAYMENT:
      return {
        ...state,
        pendingPayments: payload,
      };
    case ACTION_SET_CART_ERRORS:
      return {
        ...state,
        cartErrors: payload,
      };
    default:
      return state;
  }
};

export const setCartSession = (payload) => {
  return {
    type: ACTION_SET_CART_SESSION,
    payload,
  };
};

export const setCartContents = (payload) => {
  return {
    type: ACTION_SET_CART_CONTENTS,
    payload,
  };
};

export const setPendingPayment = (payload) => {
  return {
    type: ACTION_SET_PENDING_PAYMENT,
    payload,
  };
};

export const setCartErrors = (payload) => {
  return {
    type: ACTION_SET_CART_ERRORS,
    payload,
  };
};
