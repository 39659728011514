// WARNING: USE useWishlist HOOK IN APP
// THIS REDUCER IS MEANT TO DISTINGUISH ANONYMOUS WISHLIST FROM PROFILE WISHLIST ON LOWEST LEVEL

/** Actions' constants */
export const ACTION_SET_ANONYMOUS_LIKED_PRODUCTS = 'SET_ANONYMOUS_LIKED_PRODUCTS';
export const ACTION_ADD_ANONYMOUS_LIKED_PRODUCT = 'ADD_ANONYMOUS_LIKED_PRODUCT';
export const ACTION_REMOVE_ANONYMOUS_LIKED_PRODUCT = 'REMOVE_ANONYMOUS_LIKED_PRODUCT';

/** Initial state */
export const INITIAL_STATE =
  typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem('wishlistItems') || '[]') : [];

const getItemDate = () => {
  const date = new Date();
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_ANONYMOUS_LIKED_PRODUCTS:
      return payload;
    case ACTION_ADD_ANONYMOUS_LIKED_PRODUCT:
      return [...currentState, { price: payload.price, productId: payload.productId, date: getItemDate() }];
    case ACTION_REMOVE_ANONYMOUS_LIKED_PRODUCT:
      return currentState.filter(({ productId }) => productId !== payload);
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setAnonymousLikedProducts = (payload) => ({ payload, type: ACTION_SET_ANONYMOUS_LIKED_PRODUCTS });
export const addAnonymousLikedProduct = (productId, price) => ({
  payload: { productId, price },
  type: ACTION_ADD_ANONYMOUS_LIKED_PRODUCT,
});
export const removeAnonymousLikedProducts = (payload) => ({ payload, type: ACTION_REMOVE_ANONYMOUS_LIKED_PRODUCT });
