import { connectRouter, routerMiddleware } from 'connected-react-router';
import PropTypes from 'prop-types';

export const reducer = (history) => connectRouter(history);
export const middleware = (history) => routerMiddleware(history);

export const ROUTER_LOCATION_SHAPE = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  state: PropTypes.object,
});

export const ROUTER_HISTORY_SHAPE = PropTypes.shape({
  action: PropTypes.string.isRequired,
  block: PropTypes.func.isRequired,
  createHref: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  location: ROUTER_LOCATION_SHAPE.isRequired,
});

export const ROUTER_MATCH_SHAPE = PropTypes.shape({
  isExact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
});
