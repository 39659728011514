import flattenMessages from '../../utils/flattenMessages';

/** Initial state */
export const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE || 'en';
let defaultMessages = {};

export const INITIAL_STATE = {
  locale: DEFAULT_LANGUAGE_CODE,
  messages: defaultMessages,
};

/** Actions' constants */
export const ACTION_SET_INTL = 'SET_INTL';

export const reducer = (currentState = INITIAL_STATE, { type, payload = {} }) => {
  switch (type) {
    case ACTION_SET_INTL:
      const { locale = currentState.locale, messages = {} } = payload;
      return {
        ...currentState,
        locale,
        messages: { ...flattenMessages(defaultMessages), ...flattenMessages(messages) },
      };
    default:
      return currentState;
  }
};

/** Actions */
export const setIntl = (payload) => ({ payload, type: ACTION_SET_INTL });
