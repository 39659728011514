/** Actions' constants */
export const ACTION_SET_ClK = 'SET_ClK';

export const INITIAL_STATE = '';

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_ClK:
      return encodeURIComponent(payload);
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setClk = (payload) => ({ payload, type: ACTION_SET_ClK });
